<template>
  <div class="setting">
    <van-nav-bar
      :title="$t('changePassword')"
      left-arrow
      fixed
      @click-left="$router.go(-1)"
    />
    <div style="height: 0.9rem"></div>
    <van-cell-group :border="false">
      <van-cell :title="$t('Menu.updateCrmPwd')" is-link to="/member/crmpwd" />
      <van-cell :title="$t('Menu.updateMt4Pwd')" is-link to="/member/mt4pwd" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: "Setting",
  methods: {
    quit() {
      if (!this.$store.state.token) {
        return this.$toast.fail(this.$t("notLoggenIn"));
      }
      this.$dialog
        .confirm({
          message: this.$t("confirmExit"),
          confirmButtonText: this.$t("ok"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(() => {
          this.$store.commit("logout");
          this.$router.push("/");
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.setting {
  min-height: 100vh;
  background-color: #f7f8fa;
}
.out-login {
  padding: 16px;
}
.van-button {
  border-radius: 5px;
}
.van-cell-group {
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
}
</style>